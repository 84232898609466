import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Howl } from 'howler';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(...registerables);

// Keyframes for bomb movement animation
const bombMoveAnimation = keyframes`
  0% { transform: translate(0, 0); }
  25% { transform: translate(100px, -50px); }
  50% { transform: translate(-100px, 50px); }
  75% { transform: translate(50px, 100px); }
  100% { transform: translate(0, 0); }
`;

// Keyframes for bomb explosion animation
const explodeAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(2); opacity: 0; }
`;

// Keyframes for text flicker
const flickerAnimation = keyframes`
  0%, 100% { opacity: 1; }
  10% { opacity: 0.7; }
  20% { opacity: 0.4; }
  30% { opacity: 0.6; }
  40% { opacity: 0.2; }
  50% { opacity: 0.8; }
  60% { opacity: 0.5; }
  70% { opacity: 0.3; }
  80% { opacity: 0.9; }
  90% { opacity: 0.6; }
`;

// Bomb element styling
const BombElement = styled.div`
  position: absolute;
  width: ${(props) => (props.isExploding ? '200px' : '60px')};
  height: ${(props) => (props.isExploding ? '200px' : '60px')};
  background-image: url(${(props) => (props.isExploding ? props.explosionImg : props.img)});
  background-size: cover;
  cursor: pointer;
  animation: ${(props) => (props.isExploding ? explodeAnimation : bombMoveAnimation)}
    ${(props) => (props.isExploding ? '1s' : props.animationDuration)} infinite;
  transition: all 0.3s;
  z-index: ${(props) => (props.isExploding ? '999' : '1')};
  &:hover {
    transform: scale(1.2);
  }
`;

// Keyframes for random background color flashes
const backgroundFlash = keyframes`
  0%, 100% { background-color: #222; }
  20% { background-color: #ff007f; }
  40% { background-color: #00ffcc; }
  60% { background-color: #ff6600; }
  80% { background-color: #0000ff; }
`;

// Container styling
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: ${backgroundFlash} 1s infinite;
`;

// Button styling
const WeirdButton = styled.button`
  position: absolute;
  top: 20px;
  z-index: 1000;
  padding: 20px;
  font-size: 24px;
  color: #0f0;
  background-color: #111;
  border: 2px solid #ff007f;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #ff007f;
    color: #fff;
    transform: scale(1.1);
  }
`;

const ScoreBoard = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  color: #0f0;
`;

const GlitchText = styled.div`
  position: absolute;
  font-size: 36px;
  color: #ff007f;
  animation: ${flickerAnimation} 1s infinite;
`;

// Chart Component
const ChartContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Make chart non-interactive */
`;

// Chart data styling
const chartOptions = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: '#fff',
        lineWidth: 1,
        borderDash: [5, 5], // Dashed grid lines
      },
    },
    x: {
      grid: {
        color: '#fff',
        lineWidth: 1,
        borderDash: [5, 5], // Dashed grid lines
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
      borderWidth: 4,
      borderColor: '#ff6600', // Bright color for line
    },
    point: {
      radius: 0, // Hide points for a cleaner look
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

// Flicker text component styling
const FlickerText = styled.div`
  position: absolute;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  animation: ${flickerAnimation} 1s infinite;
`;

const SchizoGame = () => {
  const [bombs, setBombs] = useState([]);
  const [score, setScore] = useState(0);
  const [texts, setTexts] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Market Activity',
        data: [],
        borderColor: '#ff007f',
        backgroundColor: 'rgba(255, 0, 127, 0.5)',
        fill: true,
        pointRadius: 0, // Hide points for a cleaner look
      },
    ],
  });

  const explosionSound = new Howl({
    src: ['https://www.soundjay.com/explosion/explosion-04.mp3'],
    volume: 0.5,
  });

  // Load weird sound effects
  const weirdSounds = [
    new Howl({ src: ['https://www.soundjay.com/button/sounds/button-1.mp3'], volume: 0.5 }),
    new Howl({ src: ['https://www.soundjay.com/button/sounds/button-2.mp3'], volume: 0.5 }),
    new Howl({ src: ['https://www.soundjay.com/button/sounds/button-3.mp3'], volume: 0.5 }),
  ];

  const bombImage = 'https://i.imgur.com/GicuU2K.png';
  const explosionImages = [
    'https://media.tenor.com/5acZjLl1OTAAAAAi/explosion-deltarune.gif',
    'https://c.tenor.com/ESR4JNT-UqEAAAAC/tenor.gif',
  ];

  const createBomb = () => {
    const newBomb = {
      id: Date.now(),
      top: Math.random() * 90 + '%',
      left: Math.random() * 90 + '%',
      isExploding: false,
      img: bombImage,
      explosionImg: explosionImages[Math.floor(Math.random() * explosionImages.length)],
      animationDuration: (Math.random() * 2 + 2) + 's',
    };
    setBombs((prev) => [...prev, newBomb]);
  };

  const handleBombClick = (id) => {
    explosionSound.play();
    setBombs((prev) =>
      prev.map((bomb) => (bomb.id === id ? { ...bomb, isExploding: true } : bomb))
    );

    setTimeout(() => {
      setBombs((prev) => prev.filter((bomb) => bomb.id !== id));
      createBomb();
      createBomb();
      setScore((prev) => prev + 1);
      createWeirdText();
      updateChartData();
    }, 1000);
  };

  const createWeirdText = () => {
    const textMessages = ['CHAOS!', 'WTF?', 'BOOM!', 'Madness!', 'Weird!', 'Schizo Mode'];
    const randomText = textMessages[Math.floor(Math.random() * textMessages.length)];
    const newText = {
      id: Date.now(),
      text: randomText,
      top: Math.random() * 80 + '%',
      left: Math.random() * 80 + '%',
      fontSize: `${Math.random() * 30 + 20}px`,
      color: ['#ff0000', '#00ff00', '#0000ff'][Math.floor(Math.random() * 3)],
    };
    setTexts((prev) => [...prev, newText]);
    setTimeout(() => {
      setTexts((prev) => prev.filter((text) => text.id !== newText.id));
    }, 1000);
  };

  const updateChartData = () => {
    const newLabel = new Date().toLocaleTimeString();
    const newDataPoint = Math.random() > 0.5 ? Math.random() * 100 : -Math.random() * 100;

    setChartData((prevData) => {
      const updatedLabels = [...prevData.labels, newLabel].slice(-10); // Keep last 10 labels
      const updatedData = [...prevData.datasets[0].data, newDataPoint].slice(-10); // Keep last 10 data points

      return {
        ...prevData,
        labels: updatedLabels,
        datasets: [{ ...prevData.datasets[0], data: updatedData }],
      };
    });
  };

  useEffect(() => {
    const interval = setInterval(createBomb, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <ScoreBoard>Score: {score}</ScoreBoard>
      <WeirdButton onClick={createBomb}>Trigger the Chaos!</WeirdButton>
      <GlitchText>Welcome to $CPI Game!</GlitchText>

      {bombs.map((bomb) => (
        <BombElement
          key={bomb.id}
          style={{ top: bomb.top, left: bomb.left }}
          onClick={() => handleBombClick(bomb.id)}
          isExploding={bomb.isExploding}
          img={bomb.img}
          explosionImg={bomb.explosionImg}
          animationDuration={bomb.animationDuration}
        />
      ))}

      {texts.map((text) => (
        <FlickerText key={text.id} fontSize={text.fontSize} color={text.color} top={text.top} left={text.left}>
          {text.text}
        </FlickerText>
      ))}

      <ChartContainer>
        <Line data={chartData} options={chartOptions} />
      </ChartContainer>
    </Container>
  );
};

export default SchizoGame;
