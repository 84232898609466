// src/HomePage.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import SchizoGame from './SchizoGame'; // Import the SchizoGame component
import SequenceVideo from './Sequence.mp4';

// Keyframes for glitchy text effects
const glitchText = keyframes`
  0%, 100% { text-shadow: -3px 3px 0 rgba(255, 0, 0, 0.75), 3px -3px 0 rgba(0, 255, 0, 0.75), -3px -3px 0 rgba(0, 0, 255, 0.75); }
  25% { text-shadow: 3px 3px 0 rgba(255, 0, 0, 0.75), -3px -3px 0 rgba(0, 255, 0, 0.75), 3px -3px 0 rgba(0, 0, 255, 0.75); }
  50% { text-shadow: -3px -3px 0 rgba(255, 0, 0, 0.75), 3px 3px 0 rgba(0, 255, 0, 0.75), -3px 3px 0 rgba(0, 0, 255, 0.75); }
  75% { text-shadow: 3px -3px 0 rgba(255, 0, 0, 0.75), -3px 3px 0 rgba(0, 255, 0, 0.75), 3px 3px 0 rgba(0, 0, 255, 0.75); }
`;

// Keyframes for background animation
const noiseAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 100% 100%; }
`;

// Keyframes for rotating images
const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
`;

// Keyframes for floating effects
const floatAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

// Keyframes for random shake
const shakeAnimation = keyframes`
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(2px, -2px); }
  60% { transform: translate(-2px, -2px); }
  80% { transform: translate(2px, 2px); }
  100% { transform: translate(0); }
`;

// Container for the main homepage layout
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 20px;
  background: #111; /* Dark background */
  position: relative;
  overflow: hidden;
`;

// Glitchy text styles
const GlitchHeader = styled(motion.h1)`
  font-size: 4em;
  font-family: 'Courier New', Courier, monospace;
  animation: ${glitchText} 2s infinite;
  color: #0f0; /* Neon green */
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  transform: skew(-5deg);
`;

// Section styles
const Section = styled(motion.section)`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 80px;
  text-align: center;
  padding: 40px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #0f0;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
  border-radius: 15px;
`;

// Section header styles
const SectionHeader = styled.h2`
  font-size: 2em;
  color: #ff007f; /* Neon pink */
  margin-bottom: 20px;
`;

// Section content styles
const SectionContent = styled.p`
  font-size: 1.2em;
  color: #ddd;
  line-height: 1.6;
`;

// Button styles
const BuyButton = styled(motion.button)`
  background: linear-gradient(45deg, #ff0000, #00ff00);
  color: #000;
  border: none;
  padding: 20px 40px;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
  font-family: 'Courier New', Courier, monospace;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  margin-right: 20px; /* Space between buttons */
  &:hover {
    background: linear-gradient(45deg, #00ff00, #ff0000);
    transform: scale(1.1) rotate(3deg);
    box-shadow: 0 0 25px rgba(255, 0, 0, 0.7), 0 0 35px rgba(0, 255, 0, 0.5);
  }
`;

// Telegram Button styles
const TelegramButton = styled(BuyButton)`
  background: #0088cc; /* Telegram blue */
  &:hover {
    background: #007bb5; /* Darker Telegram blue on hover */
  }
`;

// Background noise effect
const BackgroundNoise = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: repeating-linear-gradient(
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.03) 1px,
    transparent 1px,
    transparent 3px
  );
  animation: ${noiseAnimation} 0.3s steps(10) infinite;
  pointer-events: none;
`;

// Animated Images
const AnimatedImage = styled(motion.img)`
  width: 150px;
  height: auto;
  position: absolute;
  z-index: 0;
  animation: ${rotateAnimation} 5s linear infinite; /* Added rotation animation */
`;

// Floating Elements
const FloatingElement = styled(motion.div)`
  position: absolute;
  background: rgba(255, 0, 0, 0.7);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 0;
  animation: ${floatAnimation} 2s ease-in-out infinite; /* Added floating effect */
`;

// Unique bizarre visual elements
const BizarreElement = styled(motion.div)`
  position: absolute;
  width: 50px;
  height: 50px;
  background: url('https://i.imgur.com/GicuU2K.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
  animation: ${shakeAnimation} 1s infinite;
`;

// Add the VideoWrapper styled component
const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const HomePage = () => {
  const handleTelegramButtonClick = () => {
    window.open('https://t.me/CosmicPineappleMov', '_blank'); // Replace with your Telegram channel link
  };

  return (
    <Container>
      <BackgroundNoise />
      <GlitchHeader
        animate={{ rotate: [0, 5, -5, 0], scale: [1, 1.05, 0.95, 1] }}
        transition={{ duration: 3, repeat: Infinity }}
      >
        Cosmic Pineapple Invasion
      </GlitchHeader>

      {/* Video Section */}
      <VideoWrapper>
        <video
          src={SequenceVideo} // Use the imported video file here
          autoPlay
          loop
          muted
          style={{ width: '80%', margin: '20px auto', display: 'block', border: '2px solid #ff007f' }}
        />
      </VideoWrapper>

      {/* Animated Images */}
      <AnimatedImage
        src="https://i.imgur.com/GicuU2K.png" // Replace with your own animated image URL
        alt="Schizo Animation 1"
        style={{ top: '20%', left: '30%' }}
        animate={{ y: [0, -10, 0, 10, 0] }}
        transition={{ duration: 4, repeat: Infinity }}
      />
      <AnimatedImage
        src="https://i.imgur.com/GicuU2K.png" // Replace with your own animated image URL
        alt="Schizo Animation 2"
        style={{ top: '50%', left: '50%' }}
        animate={{ y: [0, 10, 0, -10, 0] }}
        transition={{ duration: 3, repeat: Infinity }}
      />

      <Section>
        <SectionHeader>Join the Cosmic Pineapple Movement</SectionHeader>
        <SectionContent>
          Dive into Cosmic Pineapple Movements, wild animations, and immersive experiences. Let’s invade the cosmos together!
        </SectionContent>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <BuyButton whileHover={{ scale: 1.1 }} onClick={() => alert('Buy button clicked!')}>
            Buy $CPI
          </BuyButton>
          <TelegramButton whileHover={{ scale: 1.1 }} onClick={handleTelegramButtonClick}>
            Join Telegram
          </TelegramButton>
        </div>
      </Section>

      <SchizoGame />
    </Container>
  );
};

export default HomePage;
