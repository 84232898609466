// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import HomePage from './HomePage';

// Global styles to reset and apply basic styles for the webpage
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: 'Courier New', Courier, monospace;
    background-color: #000;
    color: #fff;
    overflow-x: hidden;
  }
`;

// Main rendering function to render the HomePage component
ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <HomePage />
  </React.StrictMode>,
  document.getElementById('root')
);
